<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>

    <template v-for="(itemsOneCategory, name) in pressItems">
      <section class="section section-localnav" :key="`title_${name}`">
        <nav class="tabnav">
          <ul class="tabnav-items">
            <li class="tabnav-offset-item">
              <div></div>
            </li>
            <li class="tabnav-item tabnav-first-item active">
              <em></em>
              <span class="tabnav-link">{{ name }}</span>
            </li>
            <li class="tabnav-offset-item">
              <div></div>
            </li>
          </ul>
        </nav>
      </section>
      <section class="section section-tiles" :key="`section_${name}`">
        <div class="container m-container-only-s">
          <ul>
            <li v-for="(item, index) in itemsOneCategory" :key="index">
              <router-link
                :to="isBox ? item.pathToIndex.replace('/index.json', '') : ''"
              >
                <figure
                  v-bind:style="{
                    'background-image': 'url(/storage/' + item.image + ')'
                  }"
                ></figure>
                <p>{{ item.title }}</p>
              </router-link>
            </li>
          </ul>
        </div>
      </section>
    </template>
  </main>
</template>

<script>
import isFlight from "@/mixins/isFlight";

export default {
  name: "Press",
  mixins: [isFlight],
  title() {
    return this.$t("pages.press");
  },
  data: () => {
    return {
      items: []
    };
  },
  computed: {
    pressItems() {
      return this.items.reduce(function(rv, x) {
        (rv[x["category"]] = rv[x["category"]] || []).push(x);
        return rv;
      }, {});
    }
  },
  async mounted() {
    const { data } = await this.axios.get("/storage/press/index.json");
    this.items = data.filter(item => !!item.id);
  }
};
</script>

<style scoped></style>
